import { useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { SetupModel } from '@models'
import { LoadingSpinner, MainContainer, TrackedIframe } from '@components'

import styles from './styles'

const {
  P3F_POWERTRANZ_PAYER_SETUP_ALLOWED_ORIGINS:
    POWERTRANZ_PAYER_SETUP_ALLOWED_ORIGINS
} = p3fenv

type props = {
  setupResult?: SetupModel
  onComplete: () => void
}

const SetupIframe: React.FC<props> = ({ setupResult, onComplete }) => {
  const [isIframeVisible, setIsIframeVisible] = useState<boolean>(false)
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current
      const doc = iframe.contentWindow?.document
      doc?.open()
      doc?.write(setupResult?.htmlContent ?? '')
      doc?.close()
    }
  }, [setupResult])

  return (
    <MainContainer showBrand={false} showAsAPlugin={false}>
      <div className={styles}>
        <div
          className={cn('background-loader', {
            'in-front': !isIframeVisible
          })}
        >
          <LoadingSpinner size={56} />
        </div>
        <TrackedIframe
          id="threedsIframe"
          ref={iframeRef}
          processor="POWERTRANZ"
          stepDescriptor="PAYER_STEPUP"
          validOrigins={POWERTRANZ_PAYER_SETUP_ALLOWED_ORIGINS.split(',')}
          onValidOriginEventCallback={async (eventResult: any) => {
            const type = eventResult['MessageType']
            if (type === 'authentication.verified') onComplete()
          }}
          onLoadCallback={() => {
            setIsIframeVisible(true)
          }}
        />
      </div>
    </MainContainer>
  )
}

export default SetupIframe
