import { useEffect, useState } from 'react'
import cn from 'classnames'
import { MainContainer, LoadingSpinner, TrackedIframe } from '@components'

// STYLES
import styles from './styles'

const { P3F_PAYER_STEPUP_ALLOWED_ORIGINS: PAYER_STEPUP_ALLOWED_ORIGINS } =
  p3fenv

type Props = {
  stepUpUrl: string
  accessToken: string
  onComplete: () => Promise<void>
}

const StepUpForm: React.FC<Props> = ({
  stepUpUrl,
  accessToken,
  onComplete
}) => {
  const [isIframeVisible, setIsIframeVisible] = useState<boolean>(false)

  useEffect(() => {
    const formCollection = document.getElementById(
      'step-up-form'
    ) as HTMLFormElement

    formCollection.submit()
  }, [])

  return (
    <MainContainer showBrand={false} showAsAPlugin={false}>
      <div className={styles}>
        <div
          className={cn('background-loader', {
            'in-front': !isIframeVisible
          })}
        >
          <LoadingSpinner size={56} />
        </div>
        <TrackedIframe
          id="step-up-iframe"
          name="step-up-iframe"
          className="iframe"
          processor="CYBERSOURCE"
          stepDescriptor="PAYER_STEPUP"
          validOrigins={PAYER_STEPUP_ALLOWED_ORIGINS.split(',')}
          onValidOriginEventCallback={async (eventResult: any) => {
            const type = eventResult['MessageType']
            if (type === 'authentication.verified') onComplete()
          }}
          onLoadCallback={() => {
            setIsIframeVisible((prevState) => !prevState)
          }}
        />
        <form
          id="step-up-form"
          target="step-up-iframe"
          method="post"
          action={stepUpUrl}
        >
          <input type="hidden" name="JWT" value={accessToken} />
        </form>
      </div>
    </MainContainer>
  )
}
export default StepUpForm
