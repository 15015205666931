import { useEffect } from 'react'
import { TrackedIframe } from '@components'

const {
  // TODO: Change the name of the environment variable
  P3F_CYBERSOURCE_PAYER_SETUP_ALLOWED_ORIGINS: PAYER_SETUP_ALLOWED_ORIGINS
} = p3fenv

type Props = {
  collectionURL: string
  accessToken: string
  onComplete: (result: any) => Promise<void>
}

const DeviceDataCollectorForm: React.FC<Props> = ({
  accessToken,
  collectionURL,
  onComplete
}) => {
  useEffect(() => {
    const formCollection = document.getElementById(
      'formFor3ds'
    ) as HTMLFormElement
    formCollection.submit()
  }, [])

  return (
    <>
      <TrackedIframe
        id="iframe3ds"
        name="ddc-iframe"
        className="hidden"
        processor="PAYWAY"
        stepDescriptor="PAYER_SETUP"
        validOrigins={PAYER_SETUP_ALLOWED_ORIGINS.split(',')}
        onValidOriginEventCallback={async (eventResult: any) => {
          const type = eventResult['MessageType']
          if (type === 'profile.completed') onComplete(eventResult)
        }}
      />
      <form
        id="formFor3ds"
        method="POST"
        target="ddc-iframe"
        action={collectionURL}
      >
        <input type="hidden" id="jwtFor3ds" name="JWT" value={accessToken} />
      </form>
    </>
  )
}

export default DeviceDataCollectorForm
