import { useEffect } from 'react'
import { TrackedIframe } from '@components'

const {
  // TODO: Change the name of the environment variable
  P3F_CYBERSOURCE_PAYER_SETUP_ALLOWED_ORIGINS: PAYER_SETUP_ALLOWED_ORIGINS
} = p3fenv

type Props = {
  collectionUrl: string
  accessToken: string
  onComplete: () => Promise<void>
}

export const SetupForm: React.FC<Props> = ({
  collectionUrl,
  accessToken,
  onComplete
}) => {
  useEffect(() => {
    const formCollection = document.getElementById(
      'cardinal_collection_form'
    ) as HTMLFormElement

    formCollection.submit()
  }, [])

  return (
    <>
      <TrackedIframe
        id="cardinal_collection_iframe"
        name="collectionIframe"
        className="hidden"
        processor="CYBERSOURCE"
        stepDescriptor="PAYER_SETUP"
        validOrigins={PAYER_SETUP_ALLOWED_ORIGINS.split(',')}
        onValidOriginEventCallback={async (eventResult: any) => {
          const type = eventResult['MessageType']
          if (type === 'profile.completed') onComplete()
        }}
      />
      <form
        id="cardinal_collection_form"
        method="POST"
        target="collectionIframe"
        action={collectionUrl}
      >
        <input
          type="hidden"
          id="cardinal_collection_form_input"
          name="JWT"
          value={accessToken}
        />
      </form>
    </>
  )
}

export default SetupForm
