import { Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { datadogLogs } from '@datadog/browser-logs'
import { FullLoading } from '@components'
import { Authentication, NotFound, Error } from './pages'

const {
  P3F_SENTRY_DSN: SENTRY_DSN,
  P3F_DATADOG_CLIENT_TOKEN: DATADOG_CLIENT_TOKEN,
  P3F_DATADOG_SERVICE: DATADOG_SERVICE
} = p3fenv

const App = () => (
  <Suspense fallback={<FullLoading />}>
    <BrowserRouter>
      <Routes>
        <Route path="/authentication/:id" element={<Authentication />} />
        <Route path="/error" element={<Error />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </Suspense>
)

if (import.meta.env.MODE === 'production' && SENTRY_DSN) {
  Sentry.init({
    environment: import.meta.env.MODE,
    dsn: SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // tracePropagationTargets: ['localhost']
      })
      // new Sentry.Replay()
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ['$ is not defined', /^\$ is not defined$/]
  })
}

if (DATADOG_CLIENT_TOKEN && DATADOG_SERVICE) {
  datadogLogs.init({
    clientToken: DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: DATADOG_SERVICE,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    telemetrySampleRate: 100,
    env: import.meta.env.MODE
  })
}

export default App
