export const getEnvironmentInfo = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  const isWebView =
    /(webview|wv)/.test(userAgent) ||
    /android.*wv/.test(userAgent) ||
    /iphone|ipod|ipad.*applewebkit(?!.*safari)/i.test(userAgent)

  const isIOS = /iphone|ipod|ipad/i.test(userAgent)
  const isAndroid = /android/i.test(userAgent)

  return {
    isWebView,
    platform: isIOS ? 'ios' : isAndroid ? 'android' : 'web',
    screenSize: {
      width: window.innerWidth,
      height: window.innerHeight
    },
    orientation: window.screen.orientation?.type || 'unknown'
  }
}
