import { css } from '@linaria/core'

const styles = css`
  @apply flex justify-center w-full h-screen p-1 opacity-0;

  @screen md {
    @apply p-0;
  }

  &.is-visible {
    @apply opacity-100;
  }

  iframe {
    @apply w-full h-full rounded-2xl;

    @screen md {
      @apply rounded-none;
    }
  }
`

export default styles
