import { useEffect, useState } from 'react'
import cn from 'classnames'
import { LoadingSpinner, MainContainer, TrackedIframe } from '@components'

// STYLES
import styles from './styles'

const {
  P3F_PAYWAY_PAYER_STEPUP_ALLOWED_ORIGINS: PAYER_STEPUP_ALLOWED_ORIGINS
} = p3fenv

type Props = {
  collectionURL: string
  accessToken: string
  onComplete: (result: any) => Promise<void>
}

const AdvancedAuthenticationForm: React.FC<Props> = ({
  accessToken,
  collectionURL,
  onComplete
}) => {
  const [isIframeVisible, setIsIframeVisible] = useState<boolean>(false)

  useEffect(() => {
    const formCollection = document.getElementById(
      'formFor3ds'
    ) as HTMLFormElement
    formCollection.submit()
  }, [])

  return (
    <MainContainer showBrand={false} showAsAPlugin={false}>
      <div id="iframe3dsOverlay" className={styles}>
        <div
          className={cn('background-loader', {
            'in-front': !isIframeVisible
          })}
        >
          <LoadingSpinner size={56} />
        </div>
        <TrackedIframe
          className="iframe"
          id="iframe3ds"
          name="iframe3ds"
          processor="PAYWAY"
          stepDescriptor="PAYER_STEPUP"
          validOrigins={PAYER_STEPUP_ALLOWED_ORIGINS.split(',')}
          onValidOriginEventCallback={async (eventResult: any) => {
            onComplete(eventResult)
          }}
          onLoadCallback={() => {
            setIsIframeVisible((prevState) => !prevState)
          }}
        />
        <form
          id="formFor3ds"
          target="iframe3ds"
          method="POST"
          action={collectionURL}
        >
          <input type="hidden" id="jwtFor3ds" name="JWT" value={accessToken} />
        </form>
      </div>
    </MainContainer>
  )
}

export default AdvancedAuthenticationForm
