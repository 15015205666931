import { forwardRef, useImperativeHandle } from 'react'
import { useParams } from 'react-router-dom'
import cn from 'classnames'
import useIframeTracking from './hooks/useIframeTracking'
import styles from './styles'

interface Props extends React.HTMLProps<HTMLIFrameElement> {
  processor: string
  stepDescriptor?: string
  validOrigins: string[]
  onValidOriginEventCallback: (eventResult: any) => Promise<void>
  onLoadCallback?: () => void
}

const TrackedIframe = forwardRef<HTMLIFrameElement, Props>(
  (
    {
      processor,
      stepDescriptor,
      validOrigins,
      src,
      onValidOriginEventCallback,
      onLoadCallback,
      ...props
    },
    externalRef
  ) => {
    const { id } = useParams()
    const checkedId = id ?? ''

    const { iframeRef, handleSendTrackingEvent } = useIframeTracking({
      authenticationId: checkedId,
      processor,
      stepDescriptor,
      src,
      validOrigins,
      onValidOriginEventCallback
    })

    useImperativeHandle(
      externalRef,
      () => {
        if (!iframeRef.current) {
          return document.createElement('iframe')
        }
        return iframeRef.current
      },
      [iframeRef.current]
    )

    return (
      <iframe
        className={cn('tracked-iframe', styles)}
        {...props}
        ref={iframeRef}
        src={src}
        loading="eager"
        onLoad={onLoadCallback}
        onError={(e) => {
          handleSendTrackingEvent('iframe_error', { url: src, error: e.type })
        }}
      />
    )
  }
)

export default TrackedIframe
