import cn from 'classnames'
import { TrackedIframe } from '@components'
import styles from './styles'

const {
  P3F_INSWITCH_CHALLENGE_CALLBACK_ALLOWED_ORIGINS:
    INSWITCH_CHALLENGE_CALLBACK_ALLOWED_ORIGINS
} = p3fenv

type Props = {
  collectionURL: string
  isVisible?: boolean
  changeIframeVisibility: (isVisible: boolean) => void
  checkAuthenticationResult: (result: any) => Promise<void>
}

const ChallengeIframe: React.FC<Props> = ({
  collectionURL,
  isVisible,
  changeIframeVisibility,
  checkAuthenticationResult
}) => (
  <div
    id="inswitch-challenge"
    className={cn('absolute', 'inswitch-iframe-container', styles, {
      'is-visible': isVisible
    })}
  >
    <TrackedIframe
      id="inswitch-challenge-iframe"
      src={collectionURL}
      processor="INSWITCH"
      onValidOriginEventCallback={checkAuthenticationResult}
      validOrigins={INSWITCH_CHALLENGE_CALLBACK_ALLOWED_ORIGINS.split(',')}
      onLoadCallback={() => {
        changeIframeVisibility(true)
      }}
    />
  </div>
)

export default ChallengeIframe
