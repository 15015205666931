import { css } from '@linaria/core'

const styles = css`
  @apply flex justify-center w-full h-full px-8 py-16 relative;

  & .background-loader {
    @apply absolute h-full hidden;

    &.in-front {
      @apply flex items-center justify-center z-20;
    }
  }

  iframe {
    @apply w-full h-full;
  }
`

export default styles
