import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthenticationModel } from '@models'
import {
  ONE_MOMENT,
  PROCESSING_SECURE_TRANSACTION
} from '@constants/translationsKeys'
import { AuthenticationStatus } from '@enums'
import { getAuthenticationStatusString } from '@utils/authentication-helpers'
import { Loading, Result } from '@components'
import { NotificationForm } from '@forms'
import { useInswitchAuth } from '../../hooks'
import { ChallengeIframe } from './components'

interface Props {
  showBrand: boolean
  initialAuthentication?: AuthenticationModel
}

interface LoadingComponentProps {
  showBrand: boolean
  t: (key: string, options?: any) => string
}

const LoadingComponent = memo<LoadingComponentProps>(({ showBrand, t }) => (
  <Loading
    showBrand={showBrand}
    message={t(ONE_MOMENT, { extra: '...' })}
    description={t(PROCESSING_SECURE_TRANSACTION, { extra: '...' })}
  />
))

const InswitchAuth = memo<Props>(({ showBrand, initialAuthentication }) => {
  const [isIframeVisible, setIsIframeVisible] = useState<boolean>(false)

  const { t } = useTranslation()
  const {
    isLoading,
    authentication,
    setup,
    showChallenge,
    showNotificationForm,
    checkAuthenticationResult
  } = useInswitchAuth({ showBrand, initialAuthentication })

  if (isLoading) return <LoadingComponent showBrand={showBrand} t={t} />

  if (showChallenge) {
    return (
      <div className="relative flex flex-col">
        {!isIframeVisible && <LoadingComponent showBrand={showBrand} t={t} />}
        <ChallengeIframe
          collectionURL={setup?.collectionUrl ?? ''}
          checkAuthenticationResult={checkAuthenticationResult}
          isVisible={isIframeVisible}
          changeIframeVisibility={(isVisible) => {
            setIsIframeVisible(isVisible)
          }}
        />
      </div>
    )
  }

  if (showNotificationForm && authentication) {
    return (
      <>
        <LoadingComponent showBrand={showBrand} t={t} />
        <NotificationForm
          status={getAuthenticationStatusString(authentication.status)}
          message={authentication.statusMsg!}
          authenticationId={authentication.authenticationId}
          orderId={authentication.orderId}
          orderAmount={authentication.orderAmount}
          redirectUrl={authentication.externalReturnUrl}
        />
      </>
    )
  }

  return (
    <Result
      isSuccessful={authentication?.status === AuthenticationStatus.SUCCESS}
      showBrand={showBrand}
      message={authentication?.statusMsg ?? ''}
    />
  )
})

export default InswitchAuth
