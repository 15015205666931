import { useTranslation } from 'react-i18next'
import { AuthenticationModel } from '@models'
import { AuthenticationStatus, AuthenticationStep } from '@enums'
import {
  ONE_MOMENT,
  PROCESSING_SECURE_TRANSACTION
} from '@constants/translationsKeys'
import { Loading, Result } from '@components'
import { NotificationForm } from '@forms'
import { usePaywayAuth } from '../../hooks'
import { DeviceDataCollectorForm, AdvancedAuthenticationForm } from './forms'
import { getAuthenticationStatusString } from '@utils/authentication-helpers'

type Props = {
  showBrand: boolean
  initialAuthentication?: AuthenticationModel
}

const PaywayAuth: React.FC<Props> = ({ showBrand, initialAuthentication }) => {
  const { t } = useTranslation()
  const {
    isLoading,
    showResult,
    authentication,
    step,
    setup,
    enroll,
    checkDeviceDataCollectorResult,
    checkAuthenticationResult
  } = usePaywayAuth({ showBrand, initialAuthentication })

  const renderLoading = () => (
    <Loading
      showBrand={showBrand}
      message={t(ONE_MOMENT, { extra: '...' })}
      description={t(PROCESSING_SECURE_TRANSACTION, { extra: '...' })}
    />
  )

  const renderResult = () => (
    <Result
      isSuccessful={authentication?.status === AuthenticationStatus.SUCCESS}
      showBrand={showBrand}
      message={authentication?.statusMsg ?? ''}
    />
  )

  const renderEnrollStep = () => (
    <>
      {renderLoading()}
      <DeviceDataCollectorForm
        accessToken={setup?.accessToken ?? ''}
        collectionURL={setup?.collectionUrl ?? ''}
        onComplete={checkDeviceDataCollectorResult}
      />
    </>
  )

  const renderStepUp = () => (
    <AdvancedAuthenticationForm
      accessToken={enroll?.accessToken ?? ''}
      collectionURL={enroll?.stepUpUrl ?? ''}
      onComplete={checkAuthenticationResult}
    />
  )

  const renderNotification = () => (
    <>
      {renderLoading()}
      <NotificationForm
        status={getAuthenticationStatusString(authentication?.status)}
        message={authentication?.statusMsg ?? ''}
        authenticationId={authentication!.authenticationId}
        orderId={authentication!.orderId}
        orderAmount={authentication!.orderAmount}
        redirectUrl={authentication!.externalReturnUrl}
      />
    </>
  )

  if (isLoading) return renderLoading()
  if (showResult) return renderResult()

  const stepComponents: Partial<Record<AuthenticationStep, () => JSX.Element>> =
    {
      [AuthenticationStep.Enroll]: renderEnrollStep,
      [AuthenticationStep.StepUp]: renderStepUp,
      [AuthenticationStep.Notification]: renderNotification
    }

  return stepComponents[step]?.() ?? renderLoading()
}

export default PaywayAuth
